import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './DonationWidget.module.scss'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { Link } from '@kogk/gatsby-theme-base'
import { useDonationStore } from '@src/stores'


export default ({ amounts, initialAmount,frequency = [], standalone, updateDonationPageAmount }) => {
  const initAmountKey = amounts?.findIndex(a => a.amount === initialAmount) // TODO: work on this
  const initialAmountKey = initAmountKey < 0 ? 0 : initAmountKey
  const { t } = useLanguage()
  const [activeFrequency, setActiveFrequency] = useState(0)
  const [amountKey, setAmountKey] = useState(initialAmountKey)
  const [customAmount, setCustomAmount] = useState(initAmountKey >= 0 ? '' : initialAmount ? initialAmount?.toLocaleString('de') : '') 
  const [closeText, setCloseText] = useState(false)

  const selectedAmount = useDonationStore((state) => state.selectedAmount)
  const setSelectedAmount = useDonationStore((state) => state.setSelectedAmount)

  useEffect(() => {
    const currentAmount =
    customAmount.replace(/\D/g, '') || amounts[amountKey]?.amount
    setSelectedAmount(Number(currentAmount))
    if(updateDonationPageAmount){
      updateDonationPageAmount(Number(currentAmount) ?? 0)
    }
  }, [amountKey, customAmount])

  return (
    <div className={cn(styles.container, { [styles.light]: standalone })}>
      {frequency.length ? (
        <>
          <div className={styles.tabs}>
            {frequency.map(({ unit }, key) => {
              return (
                <button
                  onClick={() => setActiveFrequency(key)}
                  className={cn(styles.tab, {
                    [styles.activeTab]: key === activeFrequency,
                  })}
                  key={key}
                >
                  <span>{unit}</span>
                </button>
              )
            })}
          </div>

          {frequency[activeFrequency].text !== '' &&
            frequency[activeFrequency].text !== '<p></p>' &&
            !closeText && (
              <div className={styles.text}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: frequency[activeFrequency].text,
                  }}
                />
                <button
                  onClick={() => setCloseText(true)}
                  className="link link--standard p-0"
                >
                  {frequency[activeFrequency].buttonText}
                </button>
              </div>
            )}
        </>
      ) : null}
      <div className={cn(styles.tabs, 'mt-3 pt-2')}>
        {amounts.map(({ amount }, key) => {
          return (
            <button
              onClick={() => setAmountKey(key)}
              className={cn(
                styles.tab,
                {
                  [styles.activeTab]: key === amountKey,
                },
                { [styles.lightTab]: standalone },
              )}
              key={key}
            >
              <span>{amount.toLocaleString('de')} kr.</span>
            </button>
          )
        })}
      </div>
      <div
        className={cn(styles.inputWrapper, {
          [styles.lightInputwrapper]: standalone,
        })}
      >
        <input
          className="pt-1"
          type="text"
          value={customAmount}
          placeholder={t(`styrktarverkefni|inputfield label`)}
          onInput={(e) => setCustomAmount(e.target.value)}
        />
      </div>
      {frequency.length ? (
        <Link
          className="yellow-link w-100 pt-1"
          to={`${frequency[activeFrequency].link}?amount=${selectedAmount}`}
        >
          {t(`styrktarverkefni|link label`)}
        </Link>
      ) : null}
    </div>
  )
}
